import { dashboardConstants } from "../_constants";
import { dashboardService } from "../_services";

export const dashboardActions = {
  // getAllBulletin,
  // viewDetails,
  getExpirationDates,
  getQualifications,
  getQualificationReport,
  getAffirmativeActionReport,
  getVehicleReport,
  getEmployeesBirthday,
  getResourceOrderForms,
  getCreatedResourceOrderForm,
  getEquipmentSoldReport,
  getAllInventoryReport,
  getRefresherReport,
  getMileageHobbsReport,
  getMasterList,
  getFullEmployeesDocsStatusReport,
  fetchApplicantsReport,
  fetchActivityLog,

  fetchWarningManagement,
  warningManagementAddEntry,
  warningManagementDeleteEntry,
  uploadFile,

  getFireHistory,
  updateIndividualFireHistoryEntry,
  updateFireHistoryHotlineShifts,
  getEmployeesFireHistory,
  deleteIndividualFireHistory,
  fetchFireHistoryReport,
  verifyFireHistory,
};

function getExpirationDates(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getExpirationDates(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.EXPIRATION_DATES_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.EXPIRATION_DATES_SUCCESS,
      data: response?.data?.users ?? [],
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.EXPIRATION_DATES_ERROR, error };
  }
}

function getQualifications() {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getQualifications().then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.QUALIFICATIONS_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.QUALIFICATIONS_SUCCESS,
      data: response ?? [],
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.QUALIFICATIONS_ERROR, error };
  }
}

function getQualificationReport(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getQualificationReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.QUALIFICATION_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.QUALIFICATION_REPORT_SUCCESS,
      data: response?.data?.users ?? [],
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.QUALIFICATION_REPORT_ERROR, error };
  }
}

function getVehicleReport(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getVehicleReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.VEHICLE_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.VEHICLE_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.VEHICLE_REPORT_ERROR, error };
  }
}

function getAffirmativeActionReport(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getAffirmativeActionReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.AFFIRMATIVE_ACTION_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.AFFIRMATIVE_ACTION_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.AFFIRMATIVE_ACTION_REPORT_ERROR, error };
  }
}

function getEquipmentSoldReport(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getEquipmentSoldReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.EQU_SOLD_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.EQU_SOLD_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.EQU_SOLD_REPORT_ERROR, error };
  }
}

function getAllInventoryReport(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getAllInventoryReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.ALL_INV_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.ALL_INV_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.ALL_INV_REPORT_ERROR, error };
  }
}

function getEmployeesBirthday(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getEmployeesBirthday(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.EMPLOYEE_BIRTHDAY_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.EMPLOYEE_BIRTHDAY_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.EMPLOYEE_BIRTHDAY_ERROR, error };
  }
}

function getResourceOrderForms(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      dashboardService.getResourceOrderForms(data).then(
        (list) => {
          dispatch(success(list));
          resolve(list);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return { type: dashboardConstants?.RESOURCE_ORDER_FORMS_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.RESOURCE_ORDER_FORMS_SUCCESS,
      data: response?.data?.resource_order_form_files ?? {},
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.RESOURCE_ORDER_FORMS_ERROR, error };
  }
}

function getRefresherReport(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getRefresherReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.GET_REFRESHER_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.GET_REFRESHER_REPORT_SUCCESS,
      data: response?.data?.users ?? {},
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.GET_REFRESHER_REPORT_ERROR, error };
  }
}

function getCreatedResourceOrderForm(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      dashboardService.getCreatedResourceOrderForm(data).then(
        (list) => {
          dispatch(success(list));
          resolve(list);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return { type: dashboardConstants?.CREATED_RESOURCE_ORDER_FORMS_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.CREATED_RESOURCE_ORDER_FORMS_SUCCESS,
      data: response?.data?.resource_order_forms ?? {},
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants?.CREATED_RESOURCE_ORDER_FORMS_ERROR,
      error,
    };
  }
}

function getMasterList(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getMasterList(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.GET_MASTER_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.GET_MASTER_REPORT_SUCCESS,
      data: response?.data?.users ?? {},
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.GET_MASTER_REPORT_ERROR, error };
  }
}

function getMileageHobbsReport(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getMileageHobbsReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.MILEAGE_HOBBS_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.MILEAGE_HOBBS_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.MILEAGE_HOBBS_REPORT_ERROR, error };
  }
}

function getFullEmployeesDocsStatusReport(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getMasterList(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return {
      type: dashboardConstants?.GET_MASTER_REPORT_ALL_EMPLOYEES_REQUEST,
    };
  }
  function success(response) {
    return {
      type: dashboardConstants?.GET_MASTER_REPORT_ALL_EMPLOYEES_SUCCESS,
      data: response?.data?.users ?? {},
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants?.GET_MASTER_REPORT_ALL_EMPLOYEES_ERROR,
      error,
    };
  }
}

function fetchApplicantsReport(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.fetchApplicantsReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.APPLICANTS_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.APPLICANTS_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.APPLICANTS_REPORT_ERROR, error };
  }
}

function fetchActivityLog(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.fetchActivityLog(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants.ACTIVITY_LOG_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants.ACTIVITY_LOG_SUCCESS,
      data: response.data,
    };
  }
  function failure(error) {
    return { type: dashboardConstants.ACTIVITY_LOG_ERROR, error };
  }
}

function fetchWarningManagement(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.fetchWarningManagement(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants.WARNING_MANAGEMENT_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants.WARNING_MANAGEMENT_SUCCESS,
      data: response.data,
    };
  }
  function failure(error) {
    return { type: dashboardConstants.WARNING_MANAGEMENT_ERROR, error };
  }
}

function warningManagementAddEntry(data) {
  return (dispatch) => {
    dispatch(request());
    return dashboardService.warningManagementAddEntry(data).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants.WARNING_MANAGEMENT_ADD_ENTRY_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants.WARNING_MANAGEMENT_ADD_ENTRY_SUCCESS,
      data: response,
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants.WARNING_MANAGEMENT_ADD_ENTRY_ERROR,
      error,
    };
  }
}

function warningManagementDeleteEntry(data) {
  return (dispatch) => {
    dispatch(request());
    return dashboardService.warningManagementDeleteEntry(data).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants.WARNING_MANAGEMENT_DELETE_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants.WARNING_MANAGEMENT_DELETE_SUCCESS,
      data: response,
    };
  }
  function failure(error) {
    return { type: dashboardConstants.WARNING_MANAGEMENT_DELETE_ERROR, error };
  }
}

function uploadFile(uploadData) {
  return async () => {
    const body = {
      extension: uploadData.extension,
      file: uploadData.newEntryFile,
    };

    const res = await dashboardService.getSignedDOUrl(body);
    if (res.success) {
      return dashboardService
        .uploadFile(body.file, res.data.ORIGINAL_URL)
        .then(() => {
          return {
            fileName: body.file.name,
            DOFileName: res.data.ORIGINAL_FILE,
          };
        })
        .catch((err) => {
          throw err;
        });
    }
  };
}

function getFireHistory(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getFireHistory(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.GET_INDIVIDUAL_FIRE_HISTORY_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.GET_INDIVIDUAL_FIRE_HISTORY_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants?.GET_INDIVIDUAL_FIRE_HISTORY_ERROR,
      error,
    };
  }
}

function fetchFireHistoryReport(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.fetchFireHistoryReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.GET_FIRE_HISTORY_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.GET_FIRE_HISTORY_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: dashboardConstants?.GET_FIRE_HISTORY_REPORT_FAILURE, error };
  }
}

function getEmployeesFireHistory(data) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getEmployeesFireHistory(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: dashboardConstants?.GET_EMPLOYEES_FIRE_HISTORY_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.GET_EMPLOYEES_FIRE_HISTORY_SUCCESS,
      data: response?.data ?? {},
      additional_data: response?.additional_data ?? {},
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants?.GET_EMPLOYEES_FIRE_HISTORY_ERROR,
      error,
    };
  }
}

function updateIndividualFireHistoryEntry(data) {
  return (dispatch) => {
    dispatch(request());
    return dashboardService.updateIndividualFireHistoryEntry(data).then(
      (response) => {
        dispatch(success(response.data));
        return response;
      },
      (error) => {
        dispatch(failure(error));
        throw error;
      }
    );
  };

  function request() {
    return { type: dashboardConstants?.GET_INDIVIDUAL_FIRE_HISTORY_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.GET_INDIVIDUAL_FIRE_HISTORY_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants?.GET_INDIVIDUAL_FIRE_HISTORY_ERROR,
      error,
    };
  }
}

function updateFireHistoryHotlineShifts(id, data) {
  return (dispatch) => {
    dispatch(request());
    return dashboardService.updateFireHistoryHotlineShifts(id, data).then(
      (response) => {
        dispatch(success(response));
        return response;
      },
      (error) => {
        dispatch(failure(error));
        throw error;
      }
    );
  };

  function request() {
    return { type: dashboardConstants?.GET_INDIVIDUAL_FIRE_HISTORY_REQUEST };
  }
  function success(response) {
    return {
      type: dashboardConstants?.GET_INDIVIDUAL_FIRE_HISTORY_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants?.GET_INDIVIDUAL_FIRE_HISTORY_ERROR,
      error,
    };
  }
}

function verifyFireHistory(id) {
  return (dispatch) => {
    dispatch(request());
    return dashboardService.verifyFireHistory(id).then(
      (response) => {
        dispatch(success(response.data));
        return response;
      },
      (error) => {
        dispatch(failure(error));
        throw error;
      }
    );

    function request() {
      return { type: dashboardConstants?.VERIFY_FIRE_HISTORY_REQUEST };
    }
    function success(response) {
      return {
        type: dashboardConstants?.VERIFY_FIRE_HISTORY_SUCCESS,
        data: response?.data ?? {},
      };
    }
    function failure(error) {
      return {
        type: dashboardConstants?.VERIFY_FIRE_HISTORY_ERROR,
        error,
      };
    }
  };
}

function deleteIndividualFireHistory(data) {
  return (dispatch) => {
    dispatch(request());
    return dashboardService.deleteIndividualFireHistory(data).then(
      (response) => {
        dispatch(success(response.data));
        return response;
      },
      (error) => {
        dispatch(failure(error));
        throw error;
      }
    );

    function request() {
      return {
        type: dashboardConstants?.DELETE_INDIVIDUAL_FIRE_HISTORY_REQUEST,
      };
    }
    function success(response) {
      return {
        type: dashboardConstants?.DELETE_INDIVIDUAL_FIRE_HISTORY_SUCCESS,
        data: response?.data ?? {},
      };
    }
    function failure(error) {
      return {
        type: dashboardConstants?.DELETE_INDIVIDUAL_FIRE_HISTORY_ERROR,
        error,
      };
    }
  };
}
