export const config = {
    apiUrl: process.env.REACT_APP_CLIENT_API_URL,
    s3BucketUrl: process.env.REACT_APP_CLIENT_S3_BUCKET_URL,
    assetUrl: process.env.REACT_APP_CLIENT_ASSET_URL,
    altImg: "images/no_avatar_profile.jpg",
    baseUrl: process.env.REACT_APP_CLIENT_MAIN_URL + '/',
    altInvImg: "images/inv_img.jpeg",
    mainUrl: process.env.REACT_APP_CLIENT_MAIN_URL,
    pusherKey: process.env.REACT_APP_CLIENT_PUSHER_KEY,
    pusherCluster: process.env.REACT_APP_CLIENT_PUSHER_CLUSTER,
    dsn: process.env.REACT_APP_CLIENT_DSN
};