import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import NewModal from "react-responsive-modal";
import { manifestActions } from "../_actions";
import { config } from "../_helpers";
import { AlertPopup } from "../_components/AlertPopup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";
import ReactModal from "react-modal";
import DatePicker from "react-datepicker";
import {
  getStandardCurrentDatePST,
  formatStandardDate,
} from "_helpers/helpers";
import moment from "moment";
import { toast } from "react-toastify";

ReactModal.setAppElement("#app");

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Define the sorting order
const colorOrder = ["Blue", "Red", "Yellow", "White"];

// Custom sorting function
const sortByColor = (a, b) => {
  return colorOrder.indexOf(a.colorcode) - colorOrder.indexOf(b.colorcode);
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    width: "100%",

    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',

    background: "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
  background: "transparent",
});

const currentDate = getStandardCurrentDatePST();

class SortYourCrew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resource_id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : null,
      confirmation: false,
      anyUnavailableCrew: false,
      originalCrews: [],
      touch_id: "",
      h1: this.props.edit ? "Edit Manifest" : "Create Manifest",
      alertPop: false,
      items: [],
      selected: "",
      displayEffectiveDatePopup: false,
      assignedManifest: false,
      assignedDateOn: null,
      assignedDateOff: null,
      isLoading: true,
    };
    this.goToType = this.goToType.bind(this);
    this.toProjectPositionSelection =
      this.toProjectPositionSelection.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.confirmationOpenModal = this.confirmationOpenModal.bind(this);
    this.confirmationCloseModal = this.confirmationCloseModal.bind(this);
    this.effectiveDatePopup = this.effectiveDatePopup.bind(this);
    this.effectiveDatePopupCloseModal =
      this.effectiveDatePopupCloseModal.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    droppable: "items",
    droppable2: "selected",
  };

  confirmationOpenModal() {
    this.setState({ confirmation: true });
  }

  confirmationCloseModal(flag = false) {
    let anyUnavailableCrew = this.state.anyUnavailableCrew;
    this.setState({
      confirmation: false,
      anyUnavailableCrew: anyUnavailableCrew,
    });
  }

  effectiveDatePopupCloseModal() {
    this.setState({
      displayEffectiveDatePopup: false,
    });
  }

  componentDidMount() {
    let anyUnavailableCrew;
    let oncrewList = [],
      offcrewList = [];
    this.setState({
      assignedManifest: this.props.assignedManifest,
      assignedDateOn: this.props.assignedDateOn,
      assignedDateOff: this.props.assignedDateOff,
      originalCrews: this.props.originalCrews,
    });
    if (!this.props.edit) {
      if (!this.props.prev) {
        let data = new FormData();
        var mid = sessionStorage.getItem("MID");
        data.append("resource_id", this.state.resource_id);
        if (mid == 4) {
          data.append("base_manifest_type", mid);
        } else {
          data.append("base_manifest_type", this.props.type);
        }
        this.props.dispatch(manifestActions.getUsers(data));
      } else {
        this.props.savedCrews.forEach((t) => {
          if (t.category === "oncrew") {
            oncrewList.push(t);
          } else if (t.category === "offcrew") {
            offcrewList.push(t);
          }
        });

        this.setState(
          {
            items: oncrewList,
            selected: offcrewList,
            isLoading: false,
          },
          () => {
            if (this.state.items.length > 0) {
              anyUnavailableCrew = this.state.items.some((item) => {
                return item.active == "Unavailable";
              });
              this.setState({
                anyUnavailableCrew: anyUnavailableCrew,
              });
            }
          }
        );
      }
    } else {
      if (!this.props.prev) {
        let data = new FormData();
        data.append("resource_id", this.state.resource_id);
        data.append("manifest_id", this.props.manifest_id);
        data.append("base_manifest_type", this.props.type);
        this.props.dispatch(manifestActions.getUsers(data));
      } else {
        this.props.savedCrews.forEach((t) => {
          if (t.category === "oncrew") {
            oncrewList.push(t);
          } else if (t.category === "offcrew") {
            offcrewList.push(t);
          }
        });
        this.setState(
          {
            items: oncrewList,
            selected: offcrewList,
            isLoading: false,
          },
          () => {
            if (this.state.items.length > 0) {
              anyUnavailableCrew = this.state.items.some((item) => {
                return item.active == "Unavailable";
              });
              this.setState({
                anyUnavailableCrew: anyUnavailableCrew,
              });
            }
          }
        );
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { crews } = nextProps;
    let anyUnavailableCrew;
    if (crews.list) {
      const { data, additional_data } = crews.list;

      if (data.length > 0) {
        this.arrayFilter(data);
      }

      this.setState({
        originalCrews: data,
        assignedManifest: additional_data.is_manifest_assigned,
        assignedDateOn: additional_data.date_on,
        assignedDateOff: additional_data.date_off,
      });
    }
    if (crews.list && crews.list.data) {
      anyUnavailableCrew = crews.list.data.some((item) => {
        return item.active == "Unavailable";
      });
      this.setState({
        anyUnavailableCrew: anyUnavailableCrew,
      });
    }
  }

  arrayFilter(data) {
    var oncrewList = [],
      offcrewList = [];
    data.forEach((t) => {
      if (t.category === "oncrew") {
        oncrewList.push(t);
      } else if (t.category === "offcrew") {
        offcrewList.push(t);
      }
    });
    this.setState({
      items: oncrewList,
      selected: offcrewList,
      isLoading: false,
    });
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  goToType(type) {
    if (!this.props.edit) {
      this.props.previous(type);
    } else {
      this.props.cancel(this.state.resource_id);
    }
  }

  goBack() {
    this.props.cancel(this.state.resource_id);
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  handleDateChange = (date, user, listType) => {
    this.setState((prevState) => {
      const key = listType == "items" ? "date_on" : "date_off";
      let defaultDate = currentDate;
      if (key == "date_off") {
        defaultDate =
          currentDate >= user.assignment_date_on
            ? currentDate
            : user.assignment_date_on;
      }

      // Determine if we’re updating items or selected
      const updatedList = prevState[listType].map((item) =>
        item.hash === user.hash
          ? { ...item, [key]: date ? formatStandardDate(date) : defaultDate }
          : item
      );

      return {
        [listType]: updatedList,
      };
    });
  };

  handleHotlineShiftChange = (event, userHash) => {
    const { value } = event.target;
    if (!isNaN(value) && Number(value) >= 0) {
      // Ensure non-negative numbers
      this.setState((prevState) => ({
        selected: prevState["selected"].map((user) =>
          user.hash === userHash
            ? { ...user, hotline_shifts: value }
            : { ...user, hotline_shifts: user.hotline_shifts }
        ),
      }));
    }
  };

  effectiveDatePopup() {
    let displayEffectiveDatePopup = false;

    if (this.state.assignedManifest) {
      const originalCrews = this.state.originalCrews;
      const items = this.state.items; // oncrew
      const selected = this.state.selected; // offcrew

      // Create a map of original crews for easy access
      const originalMap = new Map(
        originalCrews.map((user) => [user.hash, user])
      );

      // Process items (oncrew)
      const modifiedItems = items.map((user) => {
        const originalUser = originalMap.get(user.hash);
        const require_date_on =
          originalUser && originalUser.category === "oncrew" ? false : true; // True if the user is offcrew now
        let date_on = null;
        if (require_date_on) {
          displayEffectiveDatePopup = true;

          if (this.state.assignedDateOff) {
            date_on = user.date_on
              ? user.date_on
              : currentDate >= this.state.assignedDateOn &&
                currentDate <= this.state.assignedDateOff
              ? currentDate
              : this.state.assignedDateOn;
          } else {
            date_on = user.date_on
              ? user.date_on
              : currentDate >= this.state.assignedDateOn
              ? currentDate
              : this.state.assignedDateOn;
          }
        }
        return {
          ...user,
          require_date_on,
          date_on,
          require_date_off: false,
          date_off: null,
        }; // Spread original user properties and add require_date_off
      });

      // Process selected (offcrew)
      const modifiedSelected = selected.map((user) => {
        const originalUser = originalMap.get(user.hash);
        const require_date_off =
          originalUser && originalUser.category === "offcrew" ? false : true; // True if the user is oncrew now
        let date_off = null;
        if (require_date_off) {
          displayEffectiveDatePopup = true;

          if (this.state.assignedDateOff) {
            date_off = user.date_off
              ? user.date_off
              : currentDate >= this.state.assignedDateOn &&
                currentDate <= this.state.assignedDateOff
              ? currentDate
              : user.assignment_date_on;
          } else {
            date_off = user.date_off
              ? user.date_off
              : currentDate >= this.state.assignedDateOn
              ? currentDate
              : user.assignment_date_on;
          }
        }
        return {
          ...user,
          require_date_off,
          date_off,
          require_date_on: false,
          date_on: null,
        }; // Spread original user properties and add require_date_on
      });

      // Update state with modified lists
      this.setState({
        items: modifiedItems,
        selected: modifiedSelected,
        displayEffectiveDatePopup: displayEffectiveDatePopup,
      });
    }

    if (!displayEffectiveDatePopup) {
      this.toProjectPositionSelection();
    }
  }

  toProjectPositionSelection() {
    if (this.state.assignedManifest) {
      const unfilledHotlineShiftUsers = this.state.selected.filter(
        (user) => user.require_date_off && !user.hotline_shifts
      );

      if (unfilledHotlineShiftUsers.length > 0) {
        return toast.error("Hotline Shifts is a required field.");
      }
    }

    const onCrewValidated = this.checkForUnAvailableInOnCrew();
    if (onCrewValidated) {
      var crews = [];
      if (this.state.items.length > 0) {
        // if (this.props.type === "4") {
        //   this.state.items.filter((item, index) => {
        //     if (index < 6) {
        //       crews.push(item);
        //     }
        //     return crews;
        //   });
        // } else {
        var on = this.state.items;
        this.state.selected.map((item, index) => {
          on.push(item);
          return on;
        });
        crews = on;
        // }
        this.props.next(crews);
        this.props.storeAssignedManifest(this.state.assignedManifest);
        this.props.storeAssignedDateOn(this.state.assignedDateOn);
        this.props.storeAssignedDateOff(this.state.assignedDateOff);
        this.props.storeOriginalCrews(this.state.originalCrews);
      } else {
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: "Select at least 1 user",
          alertFunction: "",
        });
      }
    } else {
      this.setState({ anyUnavailableCrew: true }, () => {
        this.confirmationOpenModal();
      });
    }
  }

  checkForUnAvailableInOnCrew() {
    let isUnavailable = true;
    const onCrew = this.state.items;
    if (onCrew?.length) {
      for (const crew of onCrew) {
        if (crew.active === "Unavailable") {
          isUnavailable = false;
          break;
        }
      }
    }
    return isUnavailable;
  }

  getList = (id) => this.state[this.id2List[id]];

  handleOnWheel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.blur();
  };

  modifyAssignment = (item, assignmentStatus) => {
    this.setState((prevState) => {
      let updatedItems, updatedSelected;

      if (assignmentStatus) {
        item.require_date_on = true;
        item.date_on = null;
        item.require_date_off = false;
        item.date_off = null;

        // Add to `items` if not present and remove from `selected`
        const existsInItems = prevState.items.some(
          (existingItem) => existingItem.userid === item.userid
        );
        updatedItems = existsInItems
          ? prevState.items
          : [...prevState.items, item];
        updatedSelected = prevState.selected.filter(
          (existingItem) => existingItem.userid !== item.userid
        );
      } else {
        item.require_date_on = false;
        item.date_on = null;
        item.require_date_off = true;
        item.date_off = null;

        // Add to `selected` if not present and remove from `items`
        const existsInSelected = prevState.selected.some(
          (existingItem) => existingItem.userid === item.userid
        );
        updatedSelected = existsInSelected
          ? prevState.selected
          : [...prevState.selected, item];
        updatedItems = prevState.items.filter(
          (existingItem) => existingItem.userid !== item.userid
        );
      }

      // Update categories within the new `selected` and `items` arrays
      updatedSelected = updatedSelected.map((t) => ({
        ...t,
        category: "offcrew",
      }));
      updatedItems = updatedItems.map((t) => ({ ...t, category: "oncrew" }));

      // Update and sort updatedSelected
      updatedSelected = updatedSelected
        .map((t) => ({
          ...t,
          category: "offcrew",
        }))
        .sort(sortByColor);

      // Update and sort updatedItems
      updatedItems = updatedItems
        .map((t) => ({
          ...t,
          category: "oncrew",
        }))
        .sort(sortByColor);

      return {
        selected: updatedSelected,
        items: updatedItems,
      };
    });

    let anyUnavailableCrew;
    if (this.state.items) {
      anyUnavailableCrew = this.state.items.some((item) => {
        return item.active == "Unavailable";
      });
      this.setState({
        anyUnavailableCrew: anyUnavailableCrew,
      });
    }
  };

  // To be removed if drag and drop removal is finalized
  // onDragEnd = (result) => {
  //   let anyUnavailableCrew;
  //   const { source, destination } = result;

  //   // dropped outside the list
  //   if (!destination) {
  //     return;
  //   }

  //   if (source.droppableId === destination.droppableId) {
  //     const items = reorder(
  //       this.getList(source.droppableId),
  //       source.index,
  //       destination.index
  //     );

  //     let state = { items };

  //     if (source.droppableId === "droppable2") {
  //       state = { selected: items };
  //     }
  //     this.setState(state);
  //   } else {
  //     const result = move(
  //       this.getList(source.droppableId),
  //       this.getList(destination.droppableId),
  //       source,
  //       destination
  //     );

  //     this.setState({
  //       items: result.droppable,
  //       selected: result.droppable2,
  //     });
  //   }
  //   this.state.selected.forEach((t) => {
  //     t.category = "offcrew";
  //   });
  //   this.state.items.forEach((t) => {
  //     t.category = "oncrew";
  //   });
  //   if (this.state.items) {
  //     anyUnavailableCrew = this.state.items.some((item) => {
  //       return item.active == "Unavailable";
  //     });
  //     this.setState({
  //       anyUnavailableCrew: anyUnavailableCrew,
  //     });
  //   }
  // };

  render() {
    // var i=1, j = 1;
    return (
      <div>
        <NewModal
          id=""
          className=""
          open={this.state.confirmation}
          onClose={this.confirmationCloseModal.bind(this)}
        >
          <div id="statusPopup">
            <div className="modal-head">
              <h4 className="modal-title">Confirm</h4>
            </div>
            <div className="modal-body">
              <p>
                On crew list contains unvailable employees, please review or
                click on Next button to continue.
              </p>
              <br />
              {/* <button className="button m-r-1" onClick={this.confirmationCloseModal}>Cancel</button> */}
              <button
                className="button"
                onClick={() => this.confirmationCloseModal(true)}
              >
                Close
              </button>
            </div>
          </div>
        </NewModal>
        <ReactModal
          isOpen={this.state.displayEffectiveDatePopup}
          onClose={this.effectiveDatePopupCloseModal.bind(this)}
        >
          <div id="statusPopup" className="resources-popup fr-resources-popup">
            <div className="modal-head">
              <h4 className="modal-title">Confirm Effective Date</h4>
            </div>
            <div className="modal-body">
              <p>
                Please select the effective date of the employee's addition or
                removal from the crew. The selected effective dates will update
                the employee's fire history.
              </p>
              {(() => {
                let showOncrewTitle = true;
                let showOffcrewTitle = true;

                return (
                  <>
                    {/* Loop through items (oncrew members) */}
                    {this.state.items &&
                      this.state.items.map((user) =>
                        user.require_date_on ? (
                          <React.Fragment key={`item-${user.hash}`}>
                            {showOncrewTitle && (
                              <h6>
                                Effective Dates for Addition of Employees to the
                                Manifest
                              </h6>
                            )}
                            {
                              (showOncrewTitle = false) /* Set flag to false after first iteration */
                            }
                            <div className="user-date-input">
                              <figure>
                                <img
                                  className={
                                    user.active === "Available"
                                      ? "active"
                                      : "inactive"
                                  }
                                  src={
                                    user.profile_photo
                                      ? config.apiUrl + "/" + user.profile_photo
                                      : config.apiUrl + "/" + config.altImg
                                  }
                                  alt={user.last_name}
                                  onError={this.onImageError.bind(this)}
                                />
                              </figure>
                              <span className="">
                                {user.first_name + " " + user.last_name}
                              </span>
                              <DatePicker
                                type="date"
                                required
                                id={`date_on_${user.hash}`}
                                placeholderText="Date On"
                                name={`date_on_${user.hash}`}
                                selected={
                                  user.date_on ? moment(user.date_on) : null
                                }
                                onChange={(date) =>
                                  this.handleDateChange(date, user, "items")
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                                minDate={
                                  this.state.assignedDateOn
                                    ? moment(this.state.assignedDateOn)
                                    : null
                                }
                                maxDate={
                                  this.state.assignedDateOff
                                    ? moment(this.state.assignedDateOff)
                                    : null
                                }
                                onKeyDown={(e) => e.preventDefault()}
                              />
                            </div>
                          </React.Fragment>
                        ) : null
                      )}

                    {/* Loop through selected (offcrew members) */}
                    {this.state.selected &&
                      this.state.selected.map((user) =>
                        user.require_date_off ? (
                          <React.Fragment key={`selected-${user.hash}`}>
                            {showOffcrewTitle && (
                              <h6>
                                Effective Dates for Removal of Employees from
                                the Manifest
                              </h6>
                            )}
                            {
                              (showOffcrewTitle = false) /* Set flag to false after first iteration */
                            }
                            <div className="user-date-input">
                              <figure>
                                <img
                                  className={
                                    user.active === "Available"
                                      ? "active"
                                      : "inactive"
                                  }
                                  src={
                                    user.profile_photo
                                      ? config.apiUrl + "/" + user.profile_photo
                                      : config.apiUrl + "/" + config.altImg
                                  }
                                  alt={user.last_name}
                                  onError={this.onImageError.bind(this)}
                                />
                              </figure>
                              <span className="">
                                {user.first_name + " " + user.last_name}
                              </span>
                              <DatePicker
                                type="date"
                                required
                                id={`date_off_${user.hash}`}
                                placeholderText="Date Off"
                                name={`date_off_${user.hash}`}
                                selected={
                                  user.date_off ? moment(user.date_off) : null
                                }
                                onChange={(date) =>
                                  this.handleDateChange(date, user, "selected")
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                                minDate={
                                  user.assignment_date_on
                                    ? moment(user.assignment_date_on)
                                    : null
                                }
                                maxDate={
                                  this.state.assignedDateOff
                                    ? moment(this.state.assignedDateOff)
                                    : null
                                }
                                onKeyDown={(e) => e.preventDefault()}
                              />
                              <input
                                type="number"
                                style={{ width: "20%" }}
                                className="hotline_shifts"
                                name={`hotline_shifts_${user.hash}`}
                                id={`hotline_shifts_${user.hash}`}
                                field={`hotline_shifts_${user.hash}`}
                                placeholder="Hotline Shifts"
                                value={user.hotline_shifts}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) =>
                                  evt.key === "e" && evt.preventDefault()
                                }
                                onChange={(event) =>
                                  this.handleHotlineShiftChange(
                                    event,
                                    user.hash
                                  )
                                }
                              />
                            </div>
                          </React.Fragment>
                        ) : null
                      )}
                  </>
                );
              })()}
              <br />
            </div>
            <div class="btn_list">
              <button
                className="button grey"
                onClick={() => this.effectiveDatePopupCloseModal()}
              >
                Cancel
              </button>
              <button
                className="button"
                onClick={() => this.toProjectPositionSelection()}
              >
                Submit
              </button>
            </div>
          </div>
        </ReactModal>
        <div className="applicants_container sort_your_crew">
          <div className="breadcrumbs">
            <Link to={"/resources"} className="green">
              Resources
            </Link>{" "}
            / <span onClick={this.goBack.bind(this)}>Details</span> / Select
            Crew Members
          </div>
          <div className="page_title float">
            <h1>{this.state.h1}</h1>
          </div>
          <div className="manifest_type">
            <div className="inner_head">
              <h3>Select Crew Members</h3>
            </div>
            <div className="content_div inner_container ">
              <div className="sort_crew_div">
                <div className="drag_sort_wrap">
                  <div>
                    <div className="row-flex">
                      <div className="heading-section task-header">On Crew</div>
                      <div className="heading-section task-header hide-mobile">
                        Off Crew
                      </div>
                    </div>
                    {/* <div className="__drag-section">
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              className="selected drag_manifest_box dragConList"
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {this.state.items &&
                                this.state.items.map((item, index) => (
                                  <Draggable
                                    key={index}
                                    draggableId={index}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        className="draggable"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <span className="pos_hold">
                                          <b>{index + 1}</b>
                                        </span>
                                        <img
                                          className={
                                            item.active === "Available"
                                              ? "active"
                                              : "inactive"
                                          }
                                          src={
                                            item.profile_photo
                                              ? config.apiUrl +
                                                "/" +
                                                item.profile_photo
                                              : config.apiUrl +
                                                "/" +
                                                config.altImg
                                          }
                                          alt={item.last_name}
                                          onError={this.onImageError.bind(this)}
                                        />
                                        <span>
                                          {item.first_name +
                                            " " +
                                            item.last_name}
                                        </span>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        <Droppable droppableId="droppable2">
                          {(provided, snapshot) => (
                            <div
                              className="droppable droppable drag_manifest_box dragConList"
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {this.state.selected &&
                                this.state.selected.map((item, index) => (
                                  <Draggable
                                    key={index + "jj"}
                                    draggableId={index + "jj"}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        className="draggable"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <span className="pos_hold" />
                                        <img
                                          className={
                                            item.active === "Available"
                                              ? "active"
                                              : "inactive"
                                          }
                                          src={
                                            item.profile_photo
                                              ? config.apiUrl +
                                                "/" +
                                                item.profile_photo
                                              : config.apiUrl +
                                                "/" +
                                                config.altImg
                                          }
                                          alt={item.last_name}
                                          onError={this.onImageError.bind(this)}
                                        />
                                        <span>
                                          {item.first_name +
                                            " " +
                                            item.last_name}
                                        </span>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div> */}
                    <div className="ft sort-crew">
                      <div className="sort-crew__on-crew">
                        <ul role="list">
                          {this.state.items &&
                            this.state.items.map((item, index) => (
                              <li role="listitem">
                                <div
                                  className={`sort-crew__card ${item.colorcode}`}
                                >
                                  <article>
                                    <span className="sort-crew__number">
                                      {index + 1}
                                    </span>
                                    <figure>
                                      <img
                                        className={
                                          item.active === "Available"
                                            ? "active"
                                            : "inactive"
                                        }
                                        src={
                                          item.profile_photo
                                            ? config.apiUrl +
                                              "/" +
                                              item.profile_photo
                                            : config.apiUrl +
                                              "/" +
                                              config.altImg
                                        }
                                        alt={item.last_name}
                                        onError={this.onImageError.bind(this)}
                                      />
                                    </figure>
                                    <span className="sort-crew__name">
                                      {item.first_name + " " + item.last_name}
                                    </span>
                                  </article>
                                  <footer>
                                    <button
                                      onClick={this.modifyAssignment.bind(
                                        this,
                                        item,
                                        false
                                      )}
                                      className="sort-crew__btn button grey"
                                    >
                                      unassign
                                    </button>
                                  </footer>
                                </div>
                              </li>
                            ))}
                          {!this.state.isLoading &&
                            !this.state.items?.length &&
                            "No Employees"}
                        </ul>
                      </div>
                      <div className="sort-crew__off-crew">
                        <div className="row-flex hide-desk">
                          <div className="heading-section task-header">
                            Off Crew
                          </div>
                        </div>
                        <ul role="list">
                          {this.state.selected &&
                            this.state.selected.map((item, index) => (
                              <li role="listitem">
                                <div
                                  className={`sort-crew__card ${item.colorcode}`}
                                >
                                  <article>
                                    <span className="sort-crew__number">
                                      {index + 1}
                                    </span>
                                    <figure>
                                      <img
                                        className={
                                          item.active === "Available"
                                            ? "active"
                                            : "inactive"
                                        }
                                        src={
                                          item.profile_photo
                                            ? config.apiUrl +
                                              "/" +
                                              item.profile_photo
                                            : config.apiUrl +
                                              "/" +
                                              config.altImg
                                        }
                                        alt={item.last_name}
                                        onError={this.onImageError.bind(this)}
                                      />
                                    </figure>
                                    <span className="sort-crew__name">
                                      {item.first_name + " " + item.last_name}
                                    </span>
                                  </article>
                                  <footer>
                                    <button
                                      onClick={this.modifyAssignment.bind(
                                        this,
                                        item,
                                        true
                                      )}
                                      className="sort-crew__btn button"
                                    >
                                      assign
                                    </button>
                                  </footer>
                                </div>
                              </li>
                            ))}
                          {!this.state.isLoading &&
                            !this.state.selected?.length &&
                            "No Employees"}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearFix" />
                <div className="btn_list">
                  <button
                    className="button grey"
                    onClick={this.goToType.bind(this, this.props.type)}
                  >
                    Previous
                  </button>
                  <button
                    className="button"
                    onClick={() => this.effectiveDatePopup()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          {this.state.alertPop && (
            <AlertPopup
              head={this.state.alertHead}
              message={this.state.alertMsg}
              viewOpen="true"
              alertFunction={this.state.alertFunction}
              close={this.closeAlert.bind(this)}
            />
          )}
        </div>
      </div>
    );
  }
}
// ReactDOM.render(<App />, document.getElementById('root'));
// export default Drag;

function mapStateToProps(state) {
  const { crews } = state;
  return {
    crews,
  };
}

const connectedResourcePage = withRouter(
  connect(mapStateToProps)(SortYourCrew)
);
export { connectedResourcePage as SortYourCrew };
